<template>
<!--
    The subscribe page is inside the account area because it requires the user
    to be authenticated and to have an account selected to pay for the subscription.
    The signup page redirects here for new users who just created their first
    account and also for existing users who selected an account on the signup page.
    The catalog redirects here for authenticated users who were browsing the catalog
    with an account id in the query.
    This page needs to check if the user's account already has the subscription
    (if it has a per-unit price the user could purchase another unit of it, so it's
    the same subscription but quantity is increased; if it has a flat price then user
    cannot purchase more if there's already a subscription), or already has another
    subscription in the same product group (so this may be an upgrade or downgrade),
    or doesn't have it yet and can create a new subscription.
    This page needs to check if the account already has a payment method on file
    and offer to use it as the default, while still allowing user to add a new payment
    method or pay without saving a payment method.
    This page does not use the shopping cart, it prepares an order and handles
    payment for it directly using the order and payment APIs.
-->
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">
            <v-alert type="warning" v-if="networkError">
                Please check your network connection &amp; <TextButton @click="reload" underline>reload this page</TextButton>.
                <!-- <v-spacer></v-spacer> -->
                <!-- <v-btn @click="preparePaymentForm">Retry</v-btn> -->
            </v-alert>
            <!-- <template v-if="isViewReady && cart">
                <CheckoutProgress step="payment" :shipping="cart.require.require_shipping" :billing="cart.require.require_billing"/>
            </template> -->
            <!-- <p class="text-caption text-end">
            </p> -->
            <!-- The "not found" card usually is not displayed because the user is redirected to the catalog
            immediately when we don't get a result from the server; but if the redirect is taking time, the
            user would see this card. Instead of showing it like an error message, we show it as a next step
            to select a subscription from the catalog. -->
            <v-card elevation="2" v-if="isViewReady && !product">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>Subscribe</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>Continue to <router-link :to="{ name: 'brand-catalog-search', params: { brandprofile: $route.params.brandprofile }, query: { type: 'service' } }">select a subscription</router-link>.</p>
                </v-card-text>
            </v-card>
            <template v-if="isViewReady && product">
                <v-card class="mt-6">
                    <v-app-bar :color="primaryColor" dark flat dense>
                        <v-app-bar-title>Subscribe</v-app-bar-title>
                        <!-- <v-spacer/> -->
                    </v-app-bar>

                    <v-card-text>
                        <p>{{ product.name }}</p>
                        <!-- TODO: only show a short description here, not the full text; we need multiple description fields for a product so we can show short and long description, and text-only vs full content with lists, images, etc. -->
                        <p class="text-caption" v-if="product.description">{{ product.description }}</p>
                        <!-- TODO: if the product is in a product group and there are upgrades available, offer them here; all products in a product group SHOULD have the same publicly available pricing options like monthly/annually, but if one or more aren't available just show that message or change the selector when the upgrade product is chosen -->
                        <!-- <p>{{ JSON.stringify(product) }}</p> -->
                        <!-- TODO: show any required fields; for example if name/email/address are required, show the fields; can pre-fill them with current user and account info; or maybe "lock" it so it shows current info and can't be updated on the spot -->
                        <!-- TODO: choose one of available prices (radio button / big card button); we need to mark prices as "publicly visible" in admin app, so we know which ones to show here; and then some will be accessible with a code later; for now we can show just one price -->
                        <v-list v-if="product.price_list.length > 0">
                            <v-list-item v-for="price in product.price_list" :key="price.id">
                                <v-list-item-content>
                                    <!-- {{ JSON.stringify(price) }} -->
                                    <!-- <p> BILLING METHOD: {{price.billing_method}} </p> -->
                                    <!-- TODO: instead of display "Recurring charge every 1 month" , we should be able to display simply $6.95/month of $6.95/year IF the quantity is 1. for other quantities, like 2 months, 2 weeks, etc. we need to write it out like it is now. so apply the more concise form only when it's 1 day, 1 week, 1 month, 3 months (1 quarter), or 1 year. -->
                                    <PriceDisplayRecurring :price="price" class="text-center"/>
                                    <PriceDisplay :price="price" hide-free/>
                                    <!-- TODO: do we really need checkout quantity here? when signing up for a service the quantity is always 1... for things with quantities use resources or assets if there is a licensed pricing available and associated with this service... so this requires more code to check what resources and assets are associated and then if there's licensed pricing for them, show a quantity box for user to edit for each one that has licensed pricing -->
                                    <!-- <CheckoutItemQuantity :price="price" :priceId="price.id" :quantity="1" @update-quantity="updateQuantity" v-if="price.checkout_quantity_input"/> -->
                                </v-list-item-content>
                                <!-- <v-list-item-action style="align-self: flex-start;" class="mt-4"> -->
                                    <!-- TODO: instead of a signup or or get it free button here, we just show the payment form... if it's free we hide the payment form, if it's paid we show the payment form. the action button will then be at the bottom of the payment form when user submits it. THSI is where thea ction is.  so we need to show all prices and let user select a price. but it's a radio button here and user stays on this page , nota button like in the catalog. -->
                                    <!-- <v-btn :style="primaryButtonStyle" @click="buy(price)" :disabled="disabled">
                                        <PriceButtonLabelService :price="price"/>
                                    </v-btn> -->
                                <!-- </v-list-item-action> -->
                            </v-list-item>
                        </v-list>

                    </v-card-text>
                    <!-- <v-card-text>
                    <p v-if="isAtLeastOneItemInCart && total.amount_csu > 0">
                        Payment required for {{ itemCountDisplay }}.
                    </p>
                    <p v-if="isAtLeastOneItemInCart && total.amount_csu === 0">
                        No payment is due for {{ itemCountDisplay }}.
                    </p>
                    <p>
                        Amount to be charged today: {{ total.currency }} {{ formatAmount(total.currency, total.amount_csu) }}
                    </p>
                    </v-card-text> -->
                    <v-card-text>
                        <CheckoutSelectAccount :compact="true" @selected="selectAccount"></CheckoutSelectAccount>
                    </v-card-text>

                    <v-form @submit.prevent="pay" @keyup.enter.prevent="pay" class="my-4 mx-4">
                        <div id="payment-element">
                            <!-- Stripe.js injects the Payment Element -->
                        </div>
                    </v-form>
                    <!-- <v-card-text>
                    </v-card-text> -->
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn :style="primaryButtonStyle" @click="pay" v-if="isAtLeastOneItemInCart && total.amount_csu > 0" :disabled="isPayRequestPending">
                            <span class="request-pending-indicator mr-2" :style="{ 'border-top-color': primaryColor }" v-if="isPayRequestPending"></span>
                            <span>Pay now</span>
                        </v-btn>
                        <v-btn :style="primaryButtonStyle" @click="free" v-if="isAtLeastOneItemInCart && total.amount_csu === 0" :disabled="isFreeRequestPending">
                            <span class="request-pending-indicator mr-2" :style="{ 'border-top-color': primaryColor }" v-if="isFreeRequestPending"></span>
                            Get it free
                        </v-btn>
                    </v-card-actions>
                    <!-- TODO: subtotal, shipping/taxes, total -->
                    <!-- <v-form @submit.prevent="checkout" @keyup.enter.prevent="checkout">
                        <v-simple-table>
                            <template #default>
                                < ! - - <thead>
                                    <tr>
                                        <th style="text-align: start; width: 70%;">Where should we email the receipt?</th>
                                        <th style="text-align: end;"></th>
                                    </tr>
                                </thead> - - >
                                <tbody>
                                    <tr>
                                        <td style="text-align: start; vertical-align: top;" class="pt-6">
                                            <v-text-field label="Enter your email address" placeholder="" outlined dense persistent-placeholder v-model="email" ref="emailInput" :color="primaryColor" :error-messages="emailError">
                                                <template v-slot:prepend>
                                                    <font-awesome-icon icon="envelope" fixed-width/>
                                                </template>
                                            </v-text-field>
                                        </td>
                                        <td style="text-align: end; vertical-align: top;" class="pt-6">
                                            <v-btn :style="primaryButtonStyle" @click="checkout">Continue</v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-form> -->
                </v-card>
                <v-row no-gutters style="height: 100%" align="center" justify="start">
                    <TextButton :color="primaryColor" underline @click="cancel" class="my-2 text-caption">Cancel</TextButton>
                </v-row>
            </template>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}

/* this is expected to show on a disabled button so the button will appear grey; also the location where its used is expected replace the white portion with the brand's primary color */
.request-pending-indicator {
    border: 2px solid #999999; /* grey lighten-4 */
    border-top: 2px solid #ffffff; /* grey lighten-1 */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1.0s linear infinite;
    /*
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    */
}
/* NOTE: the spin is defined in index.html */
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import { toMillis } from '@libertyio/time-util-js';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import { loadStripe } from '@stripe/stripe-js';
// import CheckoutItemQuantity from '@/components/CheckoutItemQuantity.vue';
// import CheckoutItemCustomAmount from '@/components/CheckoutItemCustomAmount.vue';
// import CheckoutProgress from '@/components/CheckoutProgress.vue';
// import { isValidEmail } from '@/sdk/input';
import PriceDisplay from '@/components/PriceDisplay.vue';
import PriceDisplayRecurring from '@/components/PriceDisplayRecurring.vue';
// import CheckoutItemQuantity from '@/components/CheckoutItemQuantity.vue';
// import PriceButtonLabelService from '@/components/PriceButtonLabelService.vue';
import TextButton from '@/components/TextButton.vue';
import CheckoutSelectAccount from '@/components/CheckoutSelectAccount.vue';

let stripeAccount = null;
let stripe = null;
let stripeElements = null;
let stripePaymentElement = null;

export default {
    components: {
        // CheckoutProgress,
        // CheckoutItemQuantity,
        // CheckoutItemCustomAmount,
        CheckoutSelectAccount,
        PriceDisplay,
        PriceDisplayRecurring,
        // CheckoutItemQuantity,
        // PriceButtonLabelService,
        TextButton,
    },
    data: () => ({
        product: null,
        price: null, // the selected price
        isViewReady: false,
        // email: null,
        // emailError: null,
        // emailErrorTimeout: null,
        checkoutTimestamp: null,
        orderId: null,
        orderStatus: null,
        stripePaymentIntent: null,
        paymentAmountCSU: null,
        paymentAmountNumber: null,
        paymentAmountText: null,
        paymentCurrency: null,
        // mode: 'prompt', // 'prompt' shows line items and continue button, 'email' prompts for email
        isPayRequestPending: false, // prevents duplicate processing
        isFreeRequestPending: false, // prevents duplicate processing
        networkError: false, // true when we detect a possible network error, such as when not able to obtain stripe client secret
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
            cart: (state) => state.cart,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        total() {
            if (Array.isArray(this.cart?.totals)) {
                return this.cart.totals[0];
            }
            return {}; // { currency: 'USD', amount_csu: 0 }
        },
        itemCountDisplay() {
            if (this.cart?.items?.length === 1) {
                return '1 item';
            }
            if (this.cart?.items?.length !== 1) {
                return `${this.cart.items.length} items`;
            }
            return 'unknown items';
        },
        isAtLeastOneItemInCart() {
            return this.cart?.items?.length > 0;
        },
        frontLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-front', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'front' };
            }
            return link;
        },
        searchLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'search', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'search' };
            }
            return link;
        },
        shippingLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-shipping', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-shipping' };
            }
            return link;
        },
        billingLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-shipping', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-shipping' };
            }
            return link;
        },
        finalizeLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-finalize', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-finalize' };
            }
            return link;
        },
        paymentLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-payment', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-payment' };
            }
            return link;
        },
        checkoutAccountLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-account', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-account' };
            }
            return link;
        },
        isPaymentRequired() {
            return this.paymentCurrency && Number.isFinite(this.paymentAmountCSU) && this.paymentAmountCSU > 0;
        },
        // isCheckoutFormComplete() {
        //     return typeof this.email === 'string' && this.email.length > 0 && isValidEmail(this.email);
        // },
        stripekey() {
            return process.env.VUE_APP_STRIPE_KEY;
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        // isAuthenticated(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.init();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.init();
            }
        },
        // TODO: instead of using the cart like this, we should create a draft order and then ask server to get payment for that order, finalizing it when payment succeeds; this way a sign up / subscription process won't affect the merchandise cart that may also be in the session
        cart(newValue) {
            let total = null;
            if (newValue?.totals?.length > 0) {
                total = newValue.totals[0];
            }
            if (total?.currency && Number.isInteger(total?.amount_csu)) {
                this.paymentCurrency = total.currency;
                this.paymentAmountCSU = total.amount_csu;
                const paymentAmount = fromCurrencyAmountCSU(total.currency, total.amount_csu);
                this.paymentAmountNumber = paymentAmount.toNumber();
                this.paymentAmountText = paymentAmount.toString();
            } else {
                this.paymentCurrency = null;
                this.paymentAmountCSU = null;
                this.paymentAmountNumber = null;
                this.paymentAmountText = null;
            }
        },
        isPaymentRequired(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.preparePaymentForm();
            }
        },
    },
    methods: {
        async loadProductById(id) {
            const response = await this.$client.site(this.brandprofile).product.search({ id });
            if (!Array.isArray(response.list) || response.list.length === 0) {
                return null;
            }
            return response.list[0];
        },
        async loadProductByRef(ref) {
            const response = await this.$client.site(this.brandprofile).product.search({ ref });
            if (!Array.isArray(response.list) || response.list.length === 0) {
                return null;
            }
            return response.list[0];
        },
        async loadProductByAlias(alias) {
            const response = await this.$client.site(this.brandprofile).product.search({ alias });
            if (!Array.isArray(response.list) || response.list.length === 0) {
                return null;
            }
            return response.list[0];
        },
        /**
         * Attempt to load the specified product and price. If found, `this.product` and
         * `this.price` may be set, otherwise they will be null.
         */
        async loadProduct() {
            try {
                this.$store.commit('loading', { loadProduct: true });

                let product = null;
                if (this.$route.query.product_id) {
                    product = await this.loadProductById(this.$route.query.product_id);
                }
                if (!product && this.$route.query.product_ref) {
                    product = await this.loadProductByRef(this.$route.query.product_ref);
                }
                if (!product && this.$route.query.product_alias) {
                    product = await this.loadProductByAlias(this.$route.query.product_alias);
                }

                this.product = product;

                // all published products must have a price list, but check it just in case
                if (!Array.isArray(this.product.price_list) || this.product.price_list.length === 0) {
                    this.errorProductNotPriced = true;
                    return;
                }

                // if the user arrived with a specific price_ref or price_alias, look for it in the price list

                let price = null;
                if (this.$route.query.product_price_id) {
                    price = this.product.price_list.find((item) => item.id === this.$route.query.product_price_id);
                }
                if (!price && this.$route.query.product_price_ref) {
                    price = this.product.price_list.find((item) => item.ref === this.$route.query.product_price_ref);
                }
                if (!price && this.$route.query.product_price_alias) {
                    price = this.product.price_list.find((item) => item.alias === this.$route.query.product_price_alias);
                }
                if (!price && this.product.price_list.length === 1) {
                    // if the product has only one price, we automatically select it
                    price = this.product.price_list[0];
                }

                this.price = price;
            } catch (err) {
                console.error('loadProduct failed', err);
                this.errorProductNotFound = true;
            } finally {
                this.$store.commit('loading', { loadProduct: false });
            }
        },
        // {
        //     path: '/receipt',
        //     name: 'main-receipt',
        //     meta: { layout: 'brand-layout', isPublic: true },
        //     component: () => import(/* webpackChunkName: "receipt" */ '../views/Receipt.vue'),
        // },
        // {
        //     path: '/brand/:brandprofile/receipt',
        //     name: 'brand-receipt',
        //     meta: { layout: 'brand-layout', isPublic: true },
        //     component: () => import(/* webpackChunkName: "receipt" */ '../views/Receipt.vue'),
        // },
        // NOTE: stripe will automatically add query parameters 'payment_intent', 'payment_intent_client_secret', and 'redirect_status=succeeded' to the link when payment is done
        // TODO: take stripe payment intent id as parameter and check on that specific one
        statusLink() {
            const origin = process.env.VUE_APP_CUSTOMER_WEBSITE_URL;
            let link;
            if (this.brandselector === 'brandprofile') {
                link = `${origin}/brand/${encodeURIComponent(this.brandprofile)}/cart/payment-status`;
            } else {
                link = `${origin}/cart/payment-status`;
            }
            return link;
        },
        receiptLink(orderId) {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-receipt', params: { brandprofile: this.brandprofile }, query: { order_id: orderId } };
            } else {
                link = { name: 'main-receipt', query: { order_id: orderId } };
            }
            return link;
        },
        // redirectAuthenticatedUser() {
        //     // TODO: we need to check query parameters, if there's an organization id redirect to the dashboard for that organization, otherwise if there's only one organization, go to that one, or if there's more than one show the organization selection , and if the user doens't have any organizations then show a message that they need to contact the administrator to be added to an organization
        //     this.$router.replace({ name: 'dashboard' });
        // },
        async addToCart(item) {
            try {
                this.$store.commit('loading', { addToCart: true });
                const addItem = {
                    price_id: item.price.id,
                    amount: item.amount,
                    quantity: item.quantity,
                };
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'add', item: addItem });
                if (response.isEdited) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot add to shopping cart' });
                }
            } catch (err) {
                console.error('addToCart failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot add to shopping cart', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { addToCart: false });
            }
        },
        // removes the item from the cart completely
        async removeFromCart(priceId) {
            try {
                this.$store.commit('loading', { subtractQuantity: true });
                const item = {
                    price_id: priceId,
                };
                const response = await this.$client.site(this.brandprofile).cart.edit({ op: 'del', item });
                if (response.isEdited) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot remove item from cart' });
                }
            } catch (err) {
                console.error('removeFromCart failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot remove item from cart', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { subtractQuantity: false });
            }
        },
        async clearCart() {
            try {
                this.$store.commit('loading', { clearCart: true });
                const response = await this.$client.site(this.brandprofile).cart.delete();
                if (response.isDeleted) {
                    this.$store.commit('cart', response.cart);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot clear shopping cart' });
                }
            } catch (err) {
                console.error('clearCart failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Cannot clear shopping cart', message: 'Please try again' });
            } finally {
                this.$store.commit('loading', { clearCart: false });
            }
        },
        async init() {
            await this.loadProduct();
            // TODO: temporary use of cart until we can submit an order to get paid instead
            if (this.product && this.price) {
                await this.addToCart({ product: this.product, price: this.price, quantity: 1 });
            }
            this.isViewReady = true;
            // try {
            //     this.$store.commit('loading', { loadCartForPayment: true });
            //     await this.$store.dispatch('loadCart');
            // } catch (err) {
            //     console.error('loadCartForPayment failed', err);
            // } finally {
            //     this.$store.commit('loading', { loadCartForPayment: false });
            //     this.isViewReady = true;
            // }
        },
        async preparePaymentForm() {
            try {
                if (Number.isInteger(this.checkoutTimestamp) && this.checkoutTimestamp + 500 > Date.now()) {
                    return;
                }
                this.checkoutTimestamp = Date.now();
                this.networkError = false;
                // if (!this.isCheckoutFormComplete) {
                //     if (this.email) {
                //         this.emailError = 'Please enter a valid email address';
                //     } else {
                //         this.emailError = 'Please enter your email address';
                //     }
                //     this.$activateInput('emailInput');
                //     if (this.emailErrorTimeout) {
                //         clearTimeout(this.emailErrorTimeout);
                //     }
                //     this.emailErrorTimeout = setTimeout(() => { this.emailError = null; }, toMillis({ seconds: 10 }));
                //     return;
                // }
                // this.emailError = null;
                this.$store.commit('loading', { checkout: true });

                if (!this.stripekey) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Service is temporarily unavailable', message: 'Configuration error' });
                    return;
                }

                const response = await this.$client.site(this.brandprofile).cart.payment(/* { email: this.email } */);
                if (response.order_id) {
                    this.orderId = response.order_id;
                }
                if (response.order_status) {
                    this.orderStatus = response.order_status;
                }
                if (response.stripe_payment_intent) {
                    this.stripePaymentIntent = response.stripe_payment_intent;
                }

                if (response.order_status === 'paid') {
                    console.log('order is already paid');
                    this.$router.replace(this.receiptLink(this.orderId));
                    return;
                }

                if (response.order_status === 'pending_payment') {
                    // NOTE: 'stripeAccount' is a global defined outside 'this' Vue instance
                    if (stripe === null || stripeAccount !== response.stripe_account) {
                        // TODO: also need to check if the stripe account changed
                        stripe = await loadStripe(this.stripekey, { stripeAccount: response.stripe_account });
                        stripeAccount = response.stripe_account;
                    }

                    /*
                    // to redirect to stripe's website for checkout:
                    if (response?.redirect) {
                        window.location.href = response.redirect;
                    } else {
                        // TODO: ??? display error message?
                    }
                    */

                    // to use stripe elements here:
                    if (response?.stripe_client_secret) {
                        // NOTE: 'stripeElements' is a global defined outside 'this' Vue instance
                        if (stripeElements === null) {
                            // TODO: if brandprofile changed during checkout (it shouldn't! ) we need to show an error message and take the user back to cart to start over
                            stripeElements = stripe.elements({
                                clientSecret: response.stripe_client_secret,
                                appearance: {
                                    theme: 'stripe', // https://stripe.com/docs/stripe-js/appearance-api#theme
                                    variables: { // https://stripe.com/docs/stripe-js/appearance-api#variables
                                        colorPrimary: this.primaryColor,
                                        colorBackground: '#ffffff',
                                        colorText: '#333333',
                                        colorDanger: '#D32F2F', // red darken-2 from https://vuetifyjs.com/en/styles/colors/#material-colors
                                    },
                                },
                                // rules: {}, // https://stripe.com/docs/stripe-js/appearance-api#rules
                                // https://stripe.com/docs/stripe-js/appearance-api#others
                            });
                        }
                        // console.log(stripeElements);
                        // https://stripe.com/docs/js/element/events
                        // TODO: set up events for change, ready, , etc. ??

                        // TODO: currently server only supports 'card' payments ... latgernatively create 'payment'
                        // stripeCardElement = stripeElements.create('card'); // https://stripe.com/docs/js/elements_object/create_element?type=card
                        // stripeCardElement.mount('#card-element');
                        // NOTE: 'stripePaymentElement' is a global defined outside 'this' Vue instance
                        if (stripePaymentElement === null) {
                            stripePaymentElement = stripeElements.create('payment'); // https://stripe.com/docs/js/elements_object/create_payment_element
                            stripePaymentElement.mount('#payment-element');
                        }
                    } else {
                        console.warn('stripe client secret not present');
                        this.networkError = true;
                    }
                }
            } catch (err) {
                console.error('checkout failed', err);
            } finally {
                this.$store.commit('loading', { checkout: false });
                this.isViewReady = true;
            }
        },
        async cancel() {
            await this.clearCart();
            this.$nav.push(this.searchLink);
        },
        formatAmount(currency, amount) {
            // return fromCurrencyAmountCSU(this.price.currency, amount).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(currency, amount ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', { // TODO: localization, if we know user's locale use that instead of en-US
                currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        // NOTE: this was from prodictprice displa , but we don't need to emit 'buy' event, we need to handle it here. so it's just for reference, that 'buy' event has price object as its parameter., which you can see in the template. it's where user selects the price, such as monthly or annual pricing. ,, if there's more than one option.
        buy(price) {
            // const price = this.product.price_list.find((item) => item.id === priceId);
            // if (!price) {
            //     console.error('cannot buy item, price not found');
            //     return;
            // }
            // const quantity = this.quantityMap[price.id] ?? 1;
            // this.$emit('buy', { product: this.product, price, quantity });
            console.log(`buy called with price: ${JSON.stringify(price)}`);
        },
        // TODO: we should collect all info on this form ; because the shipping link / billing link are part ofthe cart...  OR  those pages need to accept a "return to" parameter so the user can be redirected back here after they enter the info.
        async finalize() {
            try {
                this.error = false;
                // 'payment_intent', 'payment_intent_client_secret', and 'redirect_status=succeeded'
                this.$store.commit('loading', { finalize: true });
                // send a 'finalize' request with no parameters to get requirements for the items currently in the cart
                const response = await this.$client.site(this.brandprofile).cart.finalize({});
                if (response) {
                    this.$store.commit('cart', response);
                } else {
                    this.$store.commit('cart', null);
                }
                if (this.cart.status === 'ready') {
                    // TODO: send the user to paymentLink instead, and have an indication there that user is going to do a guest checkout, or is signed in and has account X selected, and a button to use a different account would take user to checkoutAccountLink ?
                    // this.$nav.push(this.checkoutAccountLink);
                    this.$nav.push(this.paymentLink);
                    return;
                }

                // finalize API either responds with an error (400, 401, 403) or a status of 'ready' or 'finalize';
                // if (this.cart.status === 'finalize') {
                if (this.cart.finalize.includes('require_signup')) {
                    this.$nav.push(this.checkoutAccountLink);
                    return;
                }
                if (this.cart.finalize.includes('require_shipping')) {
                    this.$nav.push(this.shippingLink);
                    return;
                }
                if (this.cart.finalize.includes('require_billing')) {
                    this.$nav.push(this.billingLink);
                    return;
                }
                // TODO: we should collect all info on this form ; because the shipping link / billing link are part ofthe cart...  OR  those pages need to accept a "return to" parameter so the user can be redirected back here after they enter the info.
                // this.cart.finalize.includes('require_name') || this.cart.finalize.includes('require_email')
                this.$nav.push(this.finalizeLink);
                // return;
                // }
            } catch (err) {
                console.error('finalize failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { finalize: false });
            }
        },
        async pay() {
            if (stripe === null || stripeElements === null) {
                // TODO: maybe show this message after we tried to reload and still don't have the stripe elements
                // TODO: is there another way to get the stripe payment element to show up when it's missing?? reloading the page doesn't seem to work. user has to cancel, start over, then payment form might show up.
                // this.$bus.$emit('snackbar', { type: 'error', headline: 'Service is temporarily unavailable', message: 'Try reloading the page' });
                console.warn('Service is temporarily unavailable: Stripe elements not loaded');
                window.location.reload(true);
                return;
            }
            this.isPayRequestPending = true;
            // TODO: implement a timeout so if we don't get a response from stripe we re-enable the button to let the user try again; this is also where would implement idempotency, so if user tries again but stripe already processed the request, using the same idepotency key will prevent a duplicate charge

            // payment could fail due to invalid credit card or other reasons
            try {
                const paymentResponse = await stripe.confirmPayment({
                    elements: stripeElements,
                    confirmParams: {
                        return_url: this.statusLink(this.stripePaymentIntent),
                    },
                });
                if (paymentResponse?.error) {
                    console.error(`payment failed with error: ${JSON.stringify(paymentResponse.error)}`);
                    if (paymentResponse.error.type === 'card_error' && paymentResponse.error.message) {
                        this.$bus.$emit('snackbar', {
                            type: 'error',
                            headline: 'Card error',
                            message: paymentResponse.error.message,
                            timeout: 10000,
                        });
                    } else {
                        this.$bus.$emit('snackbar', {
                            type: 'error',
                            headline: 'Payment failed',
                            message: 'Please check the payment form and try again',
                            timeout: 10000,
                        });
                    }
                }
            } catch (err) {
                if (err) {
                    console.error('payment failed', err);
                }
            } finally {
                this.isPayRequestPending = false;
            }
        },
        async free() {
            // cannot skip payment if payment is required!
            if (this.isPaymentRequired) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Order must be paid first' });
                return;
            }
            this.isFreeRequestPending = true;
            // if we haven't already, call the payment API to mark this free order as 'paid'
            console.log(`Payment.vue: free: orderId ${this.orderId} order status ${this.orderStatus}`);
            if (!this.orderId || !this.orderStatus === 'paid') {
                const response = await this.$client.site(this.brandprofile).cart.payment(/* { email: this.email } */);
                if (response.order_id) {
                    this.orderId = response.order_id;
                }
                if (response.order_status) {
                    this.orderStatus = response.order_status;
                }
            }
            // then show the free order receipt
            if (this.orderId && ['paid', 'activated', 'processed'].includes(this.orderStatus)) {
                this.$router.replace(this.receiptLink(this.orderId));
                return;
            }
            console.log(`payment.vue: order id ${this.orderId} order status ${this.orderStatus}`);
            this.$bus.$emit('snackbar', { type: 'error', headline: 'There was a problem with the order' });
            this.isFreeRequestPending = false;
        },
        reload() {
            window.location.reload();
        },
        /**
         * NOTE: in this activity, selecting an account ONLY changes the account set in the
         * cart, and does NOT automatically proceed to the next step.
         */
        async selectAccount(accountId) {
            console.log(`subscriptioncreate.vue: selectAccount ${accountId}`);
            // NOTE: the accountList is really linkAccountUserList, so each item in it is not exactly the same as an account item; for this reason we load the account here and not just assign the selected item
            this.$nav.set('account_id', accountId);
            await this.$client.site(this.brandprofile).cart.edit({ op: 'set_account', account_id: accountId });
            // update query parameters with the account id
            if (this.$route.query.accountId !== accountId) {
                this.$router.replace({ name: this.$route.name, params: this.$route.params, query: { ...this.$route.query, accountId, t: Date.now() } });
            }
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.brandprofile) {
            this.init();
        }
    },
};
</script>
